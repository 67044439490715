<template>
    <form-layout :title="t('EXERCISE_GROUP_EDIT_PAGE_TITLE')" :breadcrumbs="breadcrumbs" @confirm="onConfirm" @cancel="onCancel" :ready="!!exerciseGroup">
        <template #form>
            <form-section :label="t('EXERCISE_GROUP_FORM_SECTION_TITLE_EXERCISE_ENVIRONMENT_INFORMATION')" class="cr-mt5">
                <cr-text-field label="Name" v-model="exerciseGroup!.name" required :disabled="isLoading" data-testing="edit-exercise-group-name-input"/>
                <cr-textarea label="Description" v-model="exerciseGroup!.description" :rows="8" :disabled="isLoading" data-testing="edit-exercise-group-description-input"/>
            </form-section>
            <exercise-group-availability v-model:start-time="exerciseGroup!.startTime" v-model:end-time="exerciseGroup!.endTime" :course="course" :disabled="isLoading" />
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { ICourse, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useRawObject } from '../../composables/useRawObject';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useCourseStore } from '../../stores/courseStore';
import { useExerciseGroupStore } from '../../stores/exerciseGroupStore';
import FormLayout from '../layouts/FormLayout.vue';
import FormSection from '../layouts/sections/FormSection.vue';
import ExerciseGroupAvailability from './ExerciseGroupAvailability.vue';

const { t } = useI18n();
const router = useRouter();

const props = defineProps<
{
    courseId: string;
    exerciseGroupId: string;
}>();

const courseStore = useCourseStore();
const exerciseGroupStore = useExerciseGroupStore();
const { isLoading } = storeToRefs(useApiClientStore());

const exerciseGroup = ref<IExerciseGroup|undefined>(undefined);

const course = ref<ICourse>();

watch(() => props.exerciseGroupId, async () =>
{
    exerciseGroup.value = await exerciseGroupStore.getCourseExerciseGroup(props.courseId, props.exerciseGroupId);
}, { immediate: true });

watch(()=>props.courseId, async () =>
{
    course.value = await courseStore.getCourse(props.courseId);
}, { immediate: true });

async function onConfirm()
{
    const fields: Array<keyof IExerciseGroup> = ['id','courseId','name','description','startTime','endTime'];
    await useExerciseGroupStore().update(useRawObject(exerciseGroup.value!, { only: fields }));
    router.push({ name: Route.ExerciseGroup.name, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroupId} });
}
function onCancel()
{
    router.push({ name: Route.ExerciseGroup.name, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroupId} });
}

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
    new BreadcrumbItem(Route.Courses), 
    new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
    new BreadcrumbItem({...Route.ExerciseGroup, text: exerciseGroup.value?.name, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroupId }}),
    new BreadcrumbItem({...Route.EditExerciseGroup, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroupId }})
]));
</script>