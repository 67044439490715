<template>
     <table-layout :title="t('COURSE_USERS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #alert v-if="courseUserManagementDisabled || hasEmptyTeams">
            <cr-alert v-if="courseUserManagementDisabled" info data-testing="course-users-management-disabled-alert" class="cr-mb2">
                <span>{{ t('COURSE_USERS_EXTERNAL_MANAGEMENT_ALERT') }}</span>
            </cr-alert>
            <cr-alert v-if="hasEmptyTeams" warn data-testing="course-users-empty-team-alert" class="cr-mb2">
                <span>{{ t('COURSE_USERS_EMPTY_TEAM_ALERT', {}, {plural: emptyTeams?.length}) }}</span>
            </cr-alert>
        </template>
        <template #filter>
            <table-filter-section>
                <table-filter-section-item>
                    <role-filter v-model="filter.roles!" :roles="[UserRole.Instructor, UserRole.TA, UserRole.Student]" data-testing="course-users-role-filter"/>
                </table-filter-section-item>
            </table-filter-section>
        </template>
        <template #table>
            <cr-bulk-action-table-header v-if="canBulkDelete" :selected-count="selected?.length || 0" :page-size="bulkActionPageSize" :max-items="maxItems" @select-all="onSelectAll" @clear-selection="onUnselectAll" class="bulk-delete-header">
                <template #actions>
                    <cr-bulk-action-button :label="t('COURSE_USERS_BULK_DELETE_BUTTON_LABEL')" icon="bi-trash" @click="onBulkDeleteUsersButtonClicked"/>
                </template>
            </cr-bulk-action-table-header>
            <cr-table v-model:selected="selected" :disabled-select-items="disabledSelectItems" :items="page?.items" :headers="headers" actionable @sort="onSort" :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" data-testing="course-users-table">
                <template v-slot:loginWith="{value}">
                    <cr-identity-provider-icon :provider="value" />
                </template>
                <template v-slot:action="{item}">
                    <cr-table-action-item v-if="item.isUser" :item="item" :to="{ name: Route.Profile.name, params: { userId: item.id }}" icon="bi-person-vcard" data-testing="course-users-table-view-profile-action">
                        {{ t('COURSE_USERS_VIEW_PROFILE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canInvite(item.role, props.courseId, course?.organizationId) && (canUpdateUserCourseRole(props.courseId, course?.organizationId) || canUpdateUser(props.courseId, course?.organizationId))" :item="item" @click="onEditEntryActionItemClicked" icon="bi-pencil" data-testing="course-users-table-edit-user-action">
                        {{ t('COURSE_USERS_EDIT_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && hasMultipleTeams && canUpdateCourseTeam(props.courseId, course?.organizationId)" :item="item" @click="onMoveUserActionItemClicked" icon="bi-folder-symlink" data-testing="course-users-table-move-user-action">
                        {{ t('COURSE_USERS_MOVE_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canUpdateCourseTeam(props.courseId, course?.organizationId)" :item="item" @click="onRenameUserTeamActionItemClicked" icon="bi-folder" data-testing="course-users-table-rename-team-action">
                        {{ t('COURSE_USERS_RENAME_USER_TEAM_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && item.isAnonymous && canResetPassword" :item="item" @click="onResetPasswordButtonClicked" icon="bi-123" data-testing="course-users-table-reset-password-action">
                        {{ t('COURSE_USERS_RESET_PASSWORD_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && isNotMe(item.id) && canInvite(item.role, props.courseId, course?.organizationId) && canDeleteCourseUser(props.courseId, course?.organizationId)" :item="item" @click="onDeleteUserActionItemClicked" icon="bi-trash3" data-testing="course-users-table-delete-user-action">
                        {{ t('COURSE_USERS_DELETE_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canImpersonate(props.courseId)" :item="item" @click="onImpersonateActionItemClicked" icon="bi-person-badge-fill" data-testing="course-users-table-impersonate-action">
                        {{ t('COURSE_USERS_IMPERSONATE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canResendCourseInvitation(props.courseId, course?.organizationId)" :item="item" @click="onResendInvitationActionItemClicked" icon="bi-envelope" data-testing="course-users-table-resend-invitation-action">
                        {{ t('COURSE_USERS_RESEND_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canDeleteCourseInvitation(props.courseId, course?.organizationId)" :item="item" @click="onDeleteInvitationActionItemClicked" icon="bi-trash3" data-testing="course-users-table-delete-invitation-action">
                        {{ t('COURSE_USERS_DELETE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isTeam && canUpdateCourseTeam(props.courseId, course?.organizationId)" :item="item" @click="onRenameActionItemClicked" icon="bi-pencil" data-testing="course-users-table-rename-team-action">
                        {{ t('COURSE_USERS_RENAME_TEAM_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isTeam && canDeleteCourseTeam(props.courseId, course?.organizationId)" :item="item" @click="onDeleteActionItemClicked" icon="bi-trash3" data-testing="course-users-table-delete-team-action">
                        {{ t('COURSE_USERS_DELETE_TEAM_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isCredential && canDeleteCredential" :item="item" @click="onDeleteCredentialActionItemClicked" icon="bi-trash3" data-testing="course-users-table-delete-credential-action">
                        {{ t('COURSE_USERS_DELETE_CREDENTIAL_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
        </template>

        <template #pagination>
            <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="course-users-table-pagination">
                <template #bottom>
                    <cr-items-per-page v-model="selectedItemsPerPage" :options="itemsPerPageOptions" data-testing="course-users-items-per-page"/>
                </template>
            </cr-pagination>
        </template>


        <template #controls>
            <table-control-item v-if="canInviteUsers(props.courseId, course?.organizationId)">
                <cr-tooltip :title="inviteUsersDisabledTooltip">
                    <hint :hint-if="showAddInstructorHint" :messages="addInstructorHintMessages" :hint-body-id="addInstructorHintId">
                        <cr-button :disabled="inviteUsersDisabled" :to="Route.InviteCourseUsers" outlined :aria-describedby="addInstructorDescribedById" data-testing="invite-users-button">
                            <cr-icon>bi-person-plus-fill</cr-icon>
                            {{ t('COURSE_USERS_INVITE_USERS') }}
                        </cr-button>
                    </hint>
                </cr-tooltip>
            </table-control-item>
            <table-control-item v-if="canCreateInvitationCode(props.courseId, course?.organizationId)">
                <cr-tooltip :title="invitationCodeDisabledTooltip">
                    <cr-button :disabled="invitationCodeDisabled" :to="Route.InvitationCode" outlined data-testing="create-invitation-code-button">
                        <cr-icon>bi-code</cr-icon>
                        {{ t('COURSE_USERS_CREATE_INVITATION_CODE_BUTTON') }}
                    </cr-button>
                </cr-tooltip>
            </table-control-item>
            <table-control-item v-if="canCreateCourseTeam(props.courseId, course?.organizationId)">
                <cr-button dialog="#createTeamDialog" outlined data-testing="create-team-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('COURSE_USERS_CREATE_TEAM_BUTTON') }}
                </cr-button>
            </table-control-item>
            <table-control-item v-if="courseIsAnonymous && canCreateCredentials" >
                <cr-button :to="Route.CreateCourseCredentials" outlined data-testing="create-credentials-button">
                    <cr-icon>bi-incognito</cr-icon>
                    {{ t('COURSE_USERS_CREATE_CREDENTIALS_BUTTON') }}
                </cr-button>
            </table-control-item>
            <table-control-item v-if="courseUserManagementDisabled" >
                <cr-button :to="Route.SyncCourseUsers" outlined data-testing="sync-users-button">
                    <cr-icon>bi-arrow-repeat</cr-icon>
                    {{ t('COURSE_USERS_SYNC_USERS_BUTTON') }}
                </cr-button>
            </table-control-item>
            <create-team-dialog id="createTeamDialog" :courseId="props.courseId" @confirm="onDialogConfirmed" />
            <rename-team-dialog v-model="showRenameTeamDialog" :courseId="props.courseId" :team="selectedItem" @confirm="onDialogConfirmed" />
            <delete-team-dialog v-model="showDeleteTeamDialog" :courseId="props.courseId" :team="selectedItem" @confirm="onDialogConfirmed" />
            <resend-invitation-dialog v-model="showResendInvitationDialog" :invitation="selectedItem" @confirm="onDialogConfirmed" />
            <delete-invitation-dialog v-model="showDeleteInvitationDialog" :invitation="selectedItem" @confirm="onDialogConfirmed" />
            <delete-user-dialog v-model="showDeleteUserDialog" :courseId="props.courseId" :user="selectedItem" :allow-remove-team="allowRemoveTeamOnUserDelete" @confirm="onDialogConfirmed" />
            <move-user-dialog v-model="showMoveUserDialog" :courseId="props.courseId" :user="selectedItem" @confirm="onDialogConfirmed" />
            <edit-user-dialog v-model="showEditUserDialog" :courseId="props.courseId" :organizationId="course?.organizationId" :user="selectedItem" @confirm="onDialogConfirmed" />
            <reset-password-dialog v-model="showResetPasswordDialog" :rosterEntry="selectedItem" />
            <delete-credential-dialog v-model="showDeleteCredentialDialog" :credential="selectedItem" @confirm="onDialogConfirmed" />
            <bulk-delete-course-users-dialog v-model="showBulkDeleteUsersDialog" :course-id="courseId" :entry-ids="selected" @confirm="onDialogConfirmed"/>
        </template>
    </table-layout>
</template>

<style scoped>
/* Negative margin since we're using some of the table layout content top padding for the bulk delete header*/
.bulk-delete-header {
    margin-top: -2rem;
}
</style>

<script setup lang="ts">
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { IRosterEntry, RosterEntry, RosterFilter } from '@cyber-range/cyber-range-api-roster-client';
import { ICourse, ITeam, Team, TeamFilter, UserRole, UserStatus } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed, toRaw, watch, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../composables/useCalendar';
import { useEnum } from '../../composables/useEnum';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useCourseStore } from '../../stores/courseStore';
import { useRosterStore } from '../../stores/rosterStore';
import TableLayout from '../layouts/TableLayout.vue';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import CreateTeamDialog from './dialogs/CreateTeamDialog.vue';
import RenameTeamDialog from './dialogs/RenameTeamDialog.vue';
import DeleteTeamDialog from './dialogs/DeleteTeamDialog.vue';
import ResendInvitationDialog from './dialogs/ResendInvitationDialog.vue';
import DeleteInvitationDialog from './dialogs/DeleteInvitationDialog.vue';
import DeleteUserDialog from './dialogs/DeleteUserDialog.vue';
import MoveUserDialog from './dialogs/MoveUserDialog.vue';
import EditUserDialog from './dialogs/EditUserDialog.vue';
import ResetPasswordDialog from './dialogs/ResetPasswordDialog.vue'
import DeleteCredentialDialog from './dialogs/DeleteCredentialDialog.vue';
import Config from '../../config';
import { useQueryFilter } from '../../composables/useQueryFilter';
import { useSessionStore } from '../../stores/sessionStore';
import { useTeamStore } from '../../stores/teamStore';
import RoleFilter from '../filters/RoleFilter.vue';
import TableControlItem from '../layouts/sections/TableControlItem.vue';
import { useSubscriptionStore } from '../../stores/subscriptionStore';
import { Products } from '@cyber-range/cyber-range-api-subscription-client';
import { useSelectionStore } from '../../stores/selectionStore';
import BulkDeleteCourseUsersDialog from './dialogs/BulkDeleteCourseUsersDialog.vue';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useRouter } from 'vue-router';
import { useOrganizationStore } from '../../stores/organizationStore';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue';
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue';
import Hint from '../hints/Hint.vue';
import { useUniqueId } from '../../composables/useUniqueId';
import { useTableSelectItemsPerPage } from '../../composables/useTableSelectItemsPerPage';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';

const router = useRouter();
const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const authorizationStore = useAuthorizationStore();
const { canCreateCourseTeam, canDeleteCourseTeam, canUpdateCourseTeam, 
        canResendCourseInvitation, canDeleteCourseInvitation, canInvite,
        canDeleteCourseUser, canUpdateUserCourseRole, canUpdateUser, canCreateInvitationCode, canInviteUsers, canImpersonate  } = useAuthorizationStore();
const canResetPassword = computed(() => authorizationStore.canResetPassword(props.courseId, course.value?.organizationId));
const canDeleteCredential = computed(() => authorizationStore.canDeleteCredential(props.courseId, course.value?.organizationId));
const canCreateCredentials = computed(() => authorizationStore.canCreateCredentials(props.courseId, course.value?.organizationId));

const canBulkDelete = computed(() =>
{
    return !courseUserManagementDisabled.value
        && canDeleteCourseUser(props.courseId, course.value?.organizationId)
        && canDeleteCourseInvitation(props.courseId, course.value?.organizationId)
        && canDeleteCourseInvitation(props.courseId, course.value?.organizationId)
        && canDeleteCourseTeam(props.courseId, course.value?.organizationId)
        && canDeleteCredential.value
})

const props = defineProps<
{
    courseId:string
}>();

const currentUserId = useSessionStore().session?.userId;

const course = ref<ICourse>();
const selectedItem = ref<IRosterEntry>();
const showDeleteTeamDialog = ref<boolean>(false);
const showRenameTeamDialog = ref<boolean>(false);
const showResendInvitationDialog = ref<boolean>(false);
const showDeleteInvitationDialog = ref<boolean>(false);
const showDeleteUserDialog = ref<boolean>(false);
const showMoveUserDialog = ref<boolean>(false);
const hasMultipleTeams = ref<boolean>(false);
const showEditUserDialog = ref<boolean>(false);
const showResetPasswordDialog = ref<boolean>(false);
const showDeleteCredentialDialog = ref<boolean>(false);
const showBulkDeleteUsersDialog = ref<boolean>(false);

const allCourseRosterEntries = ref<IRosterEntry[]>();
const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                          new BreadcrumbItem(Route.Courses), 
                          new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}), 
                          new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}})]));

const refresh = async (data:Partial<RosterFilter> = {}) =>
{
    let teams:IApiPageResponse<ITeam>;
    let rosterPage: IApiPageResponse<IRosterEntry>;

    [rosterPage, teams] = await Promise.all([
                                    useRosterStore().listRosterEntries(props.courseId, new RosterFilter({...toRaw(filter), ...data })),
                                    useTeamStore().list(props.courseId, new TeamFilter({limit: 2})) ]);

    allCourseRosterEntries.value = (await useRosterStore().listRosterEntries(props.courseId))?.items; // Needed to determine if empty teams exist in course
    hasMultipleTeams.value = teams.items?.length === 2;

    return rosterPage;
}

const {
    filter,
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    onSort,
    page
} = useTableLayoutPagination(useQueryFilter(RosterFilter, {excludedStatuses: [UserStatus.PendingDeleting]}, {arrayProperties: ['roles', 'excludedStatuses']}), refresh, { clearTokenKeys: ['limit']})

const headers = [
    new TableHeaderItem({ text:t('COURSE_USERS_NAME'), key: 'name', sortable: true}),
    new TableHeaderItem({ text:t('COURSE_USERS_EMAIL'), key: 'email', sortable: true}),
    new TableHeaderItem({ text:t('COURSE_USERS_ROLE'), key: 'role', sortable: true, formatter: (v:string)=>useEnum().toDisplayEnumName(UserRole, v)}),
    new TableHeaderItem({ text:t('COURSE_USERS_TEAM_NAME'), key: 'teamName', sortable: true}),
    new TableHeaderItem({ text:t('COURSE_USERS_LOGIN_WITH'), key: 'loginWith', sortable: true, align: 'center'}),
    new TableHeaderItem({ text:t('COURSE_USERS_LAST_LOGIN_WITH'), key: 'lastLoginWith', sortable: true}),
    new TableHeaderItem( { text:t('COURSE_USERS_PENDING_SINCE'), key: 'pendingSince', sortable: true, formatter: useCalendar().toHumanDate})
];

const sortbyHeader = headers.find(h => h.sortable && filter.sortBy === h.key.toLowerCase());
if (sortbyHeader)
{
    sortbyHeader.sortOrder = filter.sortOrder;
}

const {
    selectedItemsPerPage,
    itemsPerPageOptions,
    initializeItemsPerPage,
    onSuggestedNumberOfItems
} = useTableSelectItemsPerPage(filter);

const courseUserManagementDisabled = computed(() =>
{
    return course.value?.sync?.enabled
});

const emptyTeams = computed(() =>
{
    return allCourseRosterEntries.value?.filter(entry => entry.isTeam);
});

const hasEmptyTeams = computed(() =>
{
    return !!emptyTeams.value?.length;
})

const courseIsAnonymous = computed<boolean>(() => course.value?.organizationId ? useSubscriptionStore().isSubscribedTo(course.value?.organizationId, Products.AnonymousStudentsAndTasFeatures) : false);
watch(() => course.value?.organizationId, () =>
{
    if (course.value?.organizationId)
    {
        useSubscriptionStore().fetchOrganizationSubscribedProducts(course.value?.organizationId)
    }
});

const invitationCodeDisabled = computed<boolean>(() => !course.value || courseUserManagementDisabled.value || courseIsAnonymous.value)
const invitationCodeDisabledTooltip = computed(() => courseIsAnonymous.value ? t('COURSE_USERS_ANONYMOUS_INVITATION_CODE_DISABLED_TOOLTIP') : '');

const inviteUsersDisabled = computed<boolean>(() => 
{
    return !course.value || courseUserManagementDisabled.value
        || (courseIsAnonymous.value && !useAuthorizationStore().canInvite(UserRole.Instructor, props.courseId, course.value?.organizationId))
});
const inviteUsersDisabledTooltip = computed(() => courseIsAnonymous.value ? t('COURSE_USERS_ANONYMOUS_USER_INVITATION_DISABLED_TOOLTIP') : '');

onMounted(async ()=>
{
    if (filter.limit)
    {
        // filter started with a value, so items per page wont trigger a fetch
        page.value = await refresh();
    }
    initializeItemsPerPage();

    [course.value] = await Promise.all([
                        useCourseStore().getCourse(props.courseId)
                    ]);
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId);
    selected.value = canBulkDelete.value ? useSelectionStore().getSelected(props.courseId) : undefined
});


onUnmounted(async ()=>
{
    await useRosterStore().clearCache();
})

const selected = ref<string[]|undefined>();

const clearSelected = () =>
{
    selected.value = canBulkDelete.value ? [] : undefined;
}

const maxItems = ref<number|undefined>(useSelectionStore().getMaxItems(props.courseId));

watch(()=>selected.value, ()=> 
{
    if (selected.value)
    {
        useSelectionStore().setSelected(props.courseId, selected.value);
    }
});

watch(()=>maxItems.value, ()=> 
{
    if (maxItems.value !== undefined)
    {
        useSelectionStore().setMaxItems(props.courseId, maxItems.value);
    }
});

const isNotMe = (userId:string) =>
{
    return userId !== useSessionStore().identity?.id
}

const onDialogConfirmed = async () =>
{
    clearSelected();
    maxItems.value = undefined;
    page.value = await refresh({token: ''});
}

watch(() => filter.roles, async () =>
{
    clearSelected();
});

const onDeleteActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showDeleteTeamDialog.value = true;
}

const onResetPasswordButtonClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showResetPasswordDialog.value = true;
}

const onDeleteCredentialActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showDeleteCredentialDialog.value = true;
}

const onRenameUserTeamActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = RosterEntry.fromTeam(Team.fromJson({ id: item.teamId, name: item.teamName }));
    showRenameTeamDialog.value = true;
}

const onRenameActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showRenameTeamDialog.value = true;
}

const onResendInvitationActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showResendInvitationDialog.value = true;
}

const onDeleteInvitationActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showDeleteInvitationDialog.value = true;
}

const allowRemoveTeamOnUserDelete = ref(false);
const onDeleteUserActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    const otherEntryWithSameTeamId = allCourseRosterEntries.value?.find(entry => entry.teamId === item.teamId && entry.id !== item.id);
    allowRemoveTeamOnUserDelete.value = !otherEntryWithSameTeamId;
    showDeleteUserDialog.value = true;
}

const onMoveUserActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showMoveUserDialog.value = true;
}

const onEditEntryActionItemClicked = (item:IRosterEntry) =>
{
    selectedItem.value = item;
    showEditUserDialog.value = true;
}

const onImpersonateActionItemClicked = async (item:IRosterEntry) =>
{
    if ((await useAuthenticationStore().impersonate(item.id)))
    {
        router.push(Route.Home);
    }
}

const onBulkDeleteUsersButtonClicked = () =>
{
    showBulkDeleteUsersDialog.value = true;
}

const onSelectAll = async () =>
{
    const entries = await useRosterStore().listRosterEntries(props.courseId, new RosterFilter({...toRaw(filter), limit: -1, token: "" }));
    selected.value = entries.items.map(entry => entry.id).filter(id => id !== currentUserId);
    maxItems.value = selected.value.length;
}

const onUnselectAll = () =>
{
    clearSelected();
}

const disabledSelectItems = currentUserId ? [currentUserId] : [];
const currentUserOnPage = computed(() => !!page.value?.items.find(item => item.id === currentUserId)); 

// Subtract 1 from page size when user is on page. This is to make the header display when all selectable items are selected.
const bulkActionPageSize = computed(() => selectedItemsPerPage.value ? selectedItemsPerPage.value - (currentUserOnPage.value ? 1 : 0) : 0);

// hints
const showAddInstructorHint = computed(()=>
{
    return !!(canInvite(UserRole.Instructor, props.courseId, course.value?.organizationId) && (course.value?.statistics?.instructors?.length || 0) === 0);
});
const addInstructorHintId = useUniqueId();
const addInstructorDescribedById = computed(()=>
{
    return !!showAddInstructorHint.value
        ? addInstructorHintId
        : null
});
const addInstructorHintMessages = [t('COURSE_HINTS_NO_INSTRUCTORS'),t('COURSE_HINTS_ADD_INSTRUCTOR'),t('COURSE_HINTS_USE_INVITE_USERS_BUTTON')];
</script>