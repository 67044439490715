<template>
    <read-layout :title="exerciseGroup?.name" :breadcrumbs="breadcrumbs" :ready="isReady">

        <template #top>
            <cr-row>
                <div class="cr-mb4">
                    <catalog-notes v-if="catalogEntry?.notes" :notes="catalogEntry.notes"/>
                    <exercise-group-notes v-if="showExerciseGroupNotes" :notes="exerciseGroup?.notes || []" @note-removed="onNoteRemoved"/>
                </div>
                <exercise-group-info :exercise-group="exerciseGroup!" :exercise="myExercise" :exercise-loading="myExerciseIsLoading"/>
            </cr-row>
        </template>

        <template #controls v-if="canSeeTopControls">
            <exercise-group-controls :exercise-group="exerciseGroup!" />
            <exercise-group-ctf-controls v-if="exerciseGroup?.isCtf" :exercise-group="exerciseGroup"/>
            <exercise-group-exercise-controls v-else-if="myExercise" v-model="myExercise" v-model:loading="myExerciseIsLoading" polling/>
        </template>
        
        <template #list v-if="showExerciseTable">
            <exercise-table ref="exerciseTable" :exercise-group-id="exerciseGroupId"></exercise-table>
        </template>
        
        <template #listControls v-if="canSeeListControls">
            <exercise-group-start-all-exercises v-if="canStartExercises" :exercise-group-id="exerciseGroupId" @confirm="onStartAllConfirmed"/>
            <exercise-group-inter-team-connectivity v-if="exerciseGroup && canSeeInterTeamConnectivityControls" :exercise-group="exerciseGroup" :course-id="courseId" @toggle="onToggleInterTeamConnectivity"/>
            <exercise-group-export-dns v-if="canSeeExportDns" :exercise-group-id="exerciseGroupId"/>
        </template>
    </read-layout>
</template>

<script setup lang="ts">
import { FeatureId, ICourse, IExerciseGroup } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, ComputedRef, ref, toRef, watch } from 'vue';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useMyExerciseGroupExercise } from '../../composables/useMyExerciseGroupExercise';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCourseStore } from '../../stores/courseStore';
import { useExerciseGroupStore } from '../../stores/exerciseGroupStore';
import ReadLayout from '../layouts/ReadLayout.vue';
import ExerciseGroupControls from './ExerciseGroupControls.vue';
import ExerciseGroupCtfControls from './ExerciseGroupCtfControls.vue';
import ExerciseGroupExerciseControls from './ExerciseGroupExerciseControls.vue';
import ExerciseGroupInfo from './ExerciseGroupInfo.vue';
import ExerciseTable from './ExerciseTable.vue';
import ExerciseGroupStartAllExercises from './ExerciseGroupStartAllExercises.vue';
import ExerciseGroupInterTeamConnectivity from './ExerciseGroupInterTeamConnectivity.vue'
import CatalogNotes from './CatalogNotes.vue';
import { useCatalogStore } from '../../stores/catalogStore';
import { CatalogEntry, CatalogRecipeParameterId, DnsScope } from '@cyber-range/cyber-range-api-catalog-client';
import { useOrganizationStore } from '../../stores/organizationStore';
import ExerciseGroupExportDns from './ExerciseGroupExportDns.vue';
import { useInterTeamConnectivity } from '../../composables/useInterTeamConnectivity';
import ExerciseGroupNotes from './ExerciseGroupNotes.vue';

const props = defineProps<
{
    courseId: string;
    exerciseGroupId: string;
}>();

const authorizationStore = useAuthorizationStore();

const exerciseTable = ref();
const course = ref<ICourse>();
const exerciseGroup = ref<IExerciseGroup>();
const catalogEntry = ref<CatalogEntry>();
const isReady = computed(() => !!course.value && !!exerciseGroup.value && myExerciseInitialized.value);
const showExerciseTable = computed(() => 
{
    if (!exerciseGroup.value) return false;
    if (exerciseGroup.value.isCtf) return false;
    return authorizationStore.canListExerciseGroupExercises(exerciseGroup.value.courseId, exerciseGroup.value.organizationId);
});
const canStartExercises = computed(() => !exerciseGroup.value?.isCtf && authorizationStore.canViewAllTeamsInCourse(props.courseId, course.value?.organizationId) && authorizationStore.canStartExercise(props.courseId, course.value?.organizationId));
const canSeeInterTeamConnectivityControls = computed(() => !!exerciseGroup.value?.features?.find(f=>f.id === FeatureId.InterTeamConnectivityControl) && authorizationStore.canUpdateCourseExerciseGroupFeature(props.courseId, course.value?.organizationId));
const canSeeExportDns = computed(() => authorizationStore.canViewAllTeamsInCourse(props.courseId, course.value?.organizationId) && !!exerciseGroup.value?.recipeParameters?.find(rp => rp.id === CatalogRecipeParameterId.DnsScope && rp.value === DnsScope.Group));
const exerciseGroupIsAvailable = computed(() =>
{
    const now = new Date().toISOString();
    const endTime = exerciseGroup.value?.endTime;
    const startTime = exerciseGroup.value?.startTime;
    return !((!!endTime && endTime < now) || (!!startTime && startTime > now));
})
const canSeeTopControls = computed(() => exerciseGroupIsAvailable.value || (authorizationStore.canViewDisabledExercises(props.courseId, exerciseGroup.value?.organizationId)));
const canSeeListControls = computed(() => canStartExercises.value || canSeeInterTeamConnectivityControls.value || canSeeExportDns.value);

const myExerciseIsLoading = ref(false);

const { myExercise, myExerciseInitialized } = useMyExerciseGroupExercise(exerciseGroup);

watch(()=>props.courseId, async () =>
{
    course.value = await useCourseStore().getCourse(props.courseId);
    setExerciseGroupOrganizationId();
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value?.organizationId);
}, { immediate: true });

watch(()=>props.exerciseGroupId, async () =>
{
    exerciseGroup.value = await useExerciseGroupStore().getCourseExerciseGroup(props.courseId, props.exerciseGroupId);
    setExerciseGroupOrganizationId()
}, { immediate: true });

watch(()=>exerciseGroup.value?.catalogId, async () =>
{
    if (!exerciseGroup.value?.catalogId)
    {
        catalogEntry.value = undefined
        return;
    }

    catalogEntry.value = await useCatalogStore().fetch(exerciseGroup.value?.catalogId);
}, { immediate: true });

function setExerciseGroupOrganizationId()
{
    if (exerciseGroup.value && course.value?.organizationId)
    {
        exerciseGroup.value.organizationId = course.value.organizationId;
    }
}

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
    new BreadcrumbItem(Route.Courses), 
    new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
    new BreadcrumbItem({...Route.ExerciseGroup,  text: exerciseGroup.value?.name, params: { courseId: props.courseId, exerciseGroupId: props.exerciseGroupId}}),
]));

const onStartAllConfirmed = (exerciseIds: string[]) =>
{
    // This will show the started exercises as pending. As the exercises are polled and state changes,
    // the ExerciseGroupControls will remove the pending and reveal the real status.
    exerciseTable.value.setCurrentPageExercisesLoading(exerciseIds);

    if (myExercise.value?.id && exerciseIds.includes(myExercise.value.id))
    {
        myExerciseIsLoading.value = true;
    };
}

const { toggleInterTeamConnectivity: onToggleInterTeamConnectivity } = useInterTeamConnectivity(toRef(props, 'courseId'), exerciseGroup);

const showExerciseGroupNotes = computed(() => exerciseGroup.value?.notes && authorizationStore.canRemoveNotesFromExerciseGroups(props.courseId, exerciseGroup.value?.organizationId))
const onNoteRemoved = async (noteId: string) =>
{
    await useExerciseGroupStore().removeNoteFromExerciseGroup(props.exerciseGroupId, noteId);
    exerciseGroup.value = await useExerciseGroupStore().getCourseExerciseGroup(props.courseId, props.exerciseGroupId);
}
</script>