<template>
    <cr-card>
        <cr-card-image v-if="family.logo" class="image cr-mb1 cr-p3" data-testing="catalog-family-image" :src="family.logo" alt="" align-right/>
        <cr-card-body>
            <cr-card-title tag="h2" :class="titleClass" data-testing="catalog-family-title">
                {{ family.name }}
            </cr-card-title>
            <cr-card-title v-if="showCreatedDate" tag="div" data-testing="catalog-family-created-date">
                {{ createdDate }}
            </cr-card-title>
            <cr-card-text v-describes-card data-testing="catalog-family-text">
                {{ family.description }}
            </cr-card-text>
        </cr-card-body>
    </cr-card>
</template>

<style scoped>
.catalog-family-title {
    font-size: var(--tile-title-font-size)
}
.cr-card-image {
    align-self: start;
}
</style>

<script setup lang="ts">
import { CatalogFamilyMetadataTag } from '@cyber-range/cyber-range-api-catalog-client';
import { ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { computed } from '@vue/reactivity';
import { useCalendar } from '../../composables/useCalendar';

const props = defineProps<
{
    family: ICatalogFamily
}>();

const createdDate = computed(() =>
{
    return useCalendar().toHumanDate(props.family.createdTimestamp);
});

const showCreatedDate = computed(() =>
{
    return !!props.family.metadata?.tags?.includes(CatalogFamilyMetadataTag.EnvironmentTypesSnapshot) && !!createdDate.value;
});

const titleClass = computed(()=>
{
    return !!showCreatedDate.value
        ? "cr-font-bold catalog-family-title cr-mb0"
        : "cr-font-bold catalog-family-title"
})
</script>
