<template>
    <table-layout :title="t('COURSE_APPLICATIONS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #table>
            <cr-table :items="items" :headers="headers" actionable :loading="isLoading">
                <template v-slot:action="{item}">   
                    <cr-table-action-item v-if="canDeleteCourseApplication(courseId, organizationId)" :item="item" @click="onDeleteApplicationClicked" icon="bi-trash" data-testing="course-applications-table-delete-application-action">
                        {{ t('COURSE_APPLICATIONS_DELETE_APPLICATION_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
        </template>
        <template #bottom>
            <delete-course-application-dialog v-model="showDeleteApplicationDialog" :application="selectedItem" :course-id="courseId" @confirm="onDeleteDialogConfirmed"/>
        </template>
    </table-layout>
</template>

<script setup lang="ts">
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import Route from '../../routers/route';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { CourseApplicationFilter, CourseApplicationType, ICourse, ICourseApplication } from '@cyber-range/cyber-range-api-user-client';
import { useCourseStore } from '../../stores/courseStore';
import DeleteCourseApplicationDialog from './dialogs/DeleteCourseApplicationDialog.vue'
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canDeleteCourseApplication } = useAuthorizationStore();
const { toYYYYMMDD } = useCalendar();

const props = defineProps<
{
   courseId:string,
}>();

const course = ref<ICourse>();
let selectedItem = ref<ICourseApplication>();
let items = ref<ICourseApplication[]>();

const organizationId = computed(() =>  course.value?.organizationId || "");

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                            new BreadcrumbItem(Route.Courses), 
                            new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
                            new BreadcrumbItem({...Route.CourseApplications, text: t('COURSE_APPLICATIONS_PAGE_TITLE'), params:{courseId: props.courseId, organizationId: organizationId.value}}) ]));

const formatApplicationType = (value: CourseApplicationType) =>
{
    switch (value) {
        case CourseApplicationType.Lti13:
            return t('COURSE_APPLICATIONS_TYPE_LTI');
        default:
            return value;
    }
};

const headers = [
    new TableHeaderItem({ text: t('COURSE_APPLICATIONS_APPLICATION_ID'), key: 'id', sortable: false}),
    new TableHeaderItem({ text: t('COURSE_APPLICATIONS_TYPE'), key: 'type', sortable: false, formatter: formatApplicationType}),
    new TableHeaderItem({ text: t('COURSE_APPLICATIONS_ORGANIZATION_APPLICATION_ID'), key: 'organizationApplicationId', sortable: false}),
    new TableHeaderItem({text: t('COURSE_APPLICATIONS_CREATED'), key: 'createdTimestamp', formatter: (isoString) => toYYYYMMDD(new Date(isoString))})
];

const refresh = async () =>
{
    [items.value, course.value] = await Promise.all([useCourseStore().listAllCourseApplications(new CourseApplicationFilter({courseId: props.courseId})), useCourseStore().getCourse(props.courseId)]);
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId);
}

onMounted(async () => 
{
    await refresh();
});

const showDeleteApplicationDialog = ref<boolean>(false);

const onDeleteDialogConfirmed = async () =>
{
    await refresh();
}

const onDeleteApplicationClicked = (application: ICourseApplication) =>
{
    selectedItem.value = application;
    showDeleteApplicationDialog.value = true;
}
</script>