<template>
    <read-layout :title="course?.name" :breadcrumbs="breadcrumbs" :ready="{ top: isTopReady, list: isListReady }">

        <template #top>
            <cr-row>
                <cr-col xs12 sm12 md12 lg12 xl4>
                    <cr-image v-if="course?.image" :src="course?.image" alt="" class="cr-p4" min-height="10rem" max-height="18rem" width="100%" cover="true" />
                </cr-col>
                <cr-col xs12 sm12 md12 lg12 xl8>
                    <read-section :label="t('COURSE_DESCRIPTION')" data-testing="course-description">
                        <p>{{ course?.description }}</p>
                    </read-section>

                    <read-section v-if="course?.justification" :label="t('COURSE_JUSTIFICATION')" class="cr-mt5" data-testing="course-justification">
                        <p>{{ course?.justification }}</p>
                    </read-section>

                    <read-detail-section class="cr-mt5">

                        <read-detail-section-item :label="t('COURSE_STATUS')" data-testing="course-status">
                            {{ toDisplayEnumName(CourseStatus, course?.status) }}
                            <cr-warning-text tag="div" v-if="isCoursePendingApproval" :message="course && canApproveCourses ? t('COURSE_AWAITING_APPROVAL_COURSE_TEXT') : t('COURSE_REVIEW_COURSE_TEXT')" />
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('COURSE_ENROLLMENTS')" data-testing="course-enrollments">
                            {{ course?.statistics.numberOfEnrollments }}
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('COURSE_USER_ROLE')" data-testing="course-user-role">
                            {{ courseRoles }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="businessUnitDisplayName" :label="t('COURSE_BUSINESS_UNIT')" data-testing="course-business-unit">
                            {{ businessUnitDisplayName }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="showCreation" :label="t('COURSE_CREATION')" data-testing="course-creation">
                            {{ toHuman(course?.createdTimestamp) }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="showExpiration" :label="t('COURSE_EXPIRATION')" data-testing="course-expiration">
                            {{ course?.endTime ? toHuman(toEndOfDayISOString(course.endTime)) : t('COURSE_EXPIRATION_NEVER_EXPIRE') }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="(isCoursePendingApproval || isCourseRejected) && course && canApproveCourses" :label="t('COURSE_REQUESTED_BY')" data-testing="course-requested-by">
                            <span v-if="course?.requester">{{ course?.requester.name }} ({{ course?.requester.email }})</span>
                        </read-detail-section-item>

                        <read-detail-section-item v-if="isCourseRejected" :label="t('COURSE_DENIED_BY')" data-testing="course-rejected-by">
                            <span v-if="course?.approver">{{ course?.approver.name }} ({{ course?.approver.email }})</span>
                        </read-detail-section-item>

                        <read-detail-section-item v-if="isDirectPayCOurse" :label="t('COURSE_DIRECT_PAY_LABEL')" data-testing="course-direct-pay">
                            {{ t('COURSE_DIRECT_PAY_TEXT') }}
                        </read-detail-section-item>
                    </read-detail-section>
                </cr-col>
            </cr-row>
        </template>

        <template #controls v-if="courseCanBeManaged">
            <read-control-item v-if="isCoursePendingApproval && canApproveCourses">
                <cr-button @click="onApproveCourseClicked" background-color="cr-course-approve-background-color" :disabled="isLoading" data-testing="approve-course-button">
                    <cr-icon>bi-check</cr-icon>
                    {{ t('COURSES_COURSE_APPROVE_COURSE_BUTTON') }}
                </cr-button>
                <course-approved-dialog v-model="showCourseApprovedDialog" @confirm="onCourseApprovedConfirmed"/>
            </read-control-item>
            <read-control-item v-if="isCoursePendingApproval && canApproveCourses">
                <cr-button @click="onRejectCourseClicked" background-color="cr-course-reject-background-color" :disabled="isLoading" data-testing="reject-course-button">
                    <cr-icon>bi-x</cr-icon>
                    {{ t('COURSES_COURSE_REJECT_COURSE_BUTTON') }}
                </cr-button>
                <course-rejected-dialog v-model="showCourseRejectedDialog" @confirm="onCourseRejectedConfirmed"/>
            </read-control-item>
            <read-control-item v-if="canEditCourse(courseId, course?.organizationId)">
                <cr-button :to="Route.EditCourse" :diabled="isLoading" outlined data-testing="edit-course-button">
                    <cr-icon>bi-pencil</cr-icon>
                    {{ t('COURSES_COURSE_EDIT_COURSE_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canCreateExerciseGroup">
                <hint :hint-if="showAddEnvironmentHint" :messages="addEnvironmentHintMessages" :hint-body-id="addEnvironmentHintId">
                    <cr-button  :to="Route.CreateExerciseGroup" :diabled="isLoading" outlined :aria-describedby="addEnvironmentDescribedByHintId" data-testing="create-exercise-group-button">
                        <cr-icon>bi-display</cr-icon>
                        {{ t('COURSES_COURSE_CREATE_EXERCISE_GROUP_BUTTON') }}
                    </cr-button>
                </hint>
            </read-control-item>
            <read-control-item v-if="canListCourseUsers(courseId, course?.organizationId)">
                <hint :hint-if="showAddInstructorHint" :messages="addInstructorHintMessages" :hint-body-id="addInstructorHintId">
                    <cr-button :to="Route.CourseUsers" :diabled="isLoading" outlined :aria-describedby="addInstructorDescribedByHintId" data-testing="course-users-button">
                        <cr-icon>bi-people</cr-icon>
                        {{ t('COURSES_COURSE_COURSE_USERS_BUTTON') }}
                    </cr-button>
                </hint>
            </read-control-item>
            <read-control-item v-if="canListCourseApplications">
                <cr-button :to="Route.CourseApplications" :diabled="isLoading" outlined data-testing="course-applications-button">
                    <cr-icon>bi-grid-3x3-gap-fill</cr-icon>
                    {{ t('COURSES_COURSE_COURSE_APPLICATIONS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canViewLogs">
                <cr-button :to="{ name: Route.Logs.name, query: { objectId: courseId }}" :disabled="isLoading" outlined data-testing="course-view-logs-button">
                    <cr-icon>bi-journal-text</cr-icon>
                    {{ t('COURSES_COURSE_VIEW_LOGS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canDeleteCourse(courseId, course?.organizationId)">
                <cr-button dialog="#deleteCourseDialog" :diabled="isLoading" outlined data-testing="delete-course-button">
                    <cr-icon>bi-trash3</cr-icon>
                    {{ t('COURSES_COURSE_DELETE_COURSE_BUTTON') }}
                </cr-button>
            </read-control-item>
            <delete-course-dialog :course="course" @confirm="onDeleteButtonClicked" />
        </template>

        <template #list>
            <read-list-section :label="t('EXERCISE_GROUP_LIST_TITLE')">
                <read-list-section-item v-for="exerciseGroup in exerciseGroups">
                    <course-exercise-group-tile :courseId="courseId" :exerciseGroup="exerciseGroup"/>
                </read-list-section-item>
            </read-list-section>
        </template>
    </read-layout>
</template>

<script setup lang="ts">
import { CourseStatus, ICourse, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref, watch, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import { useCourseStore } from '../../stores/courseStore';
import { useBusinessUnitStore } from '../../stores/businessUnitStore';
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadSection from '../layouts/sections/ReadSection.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import { useCalendar } from '../../composables/useCalendar';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useEnum } from '../../composables/useEnum';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useRouter } from 'vue-router';
import { useWhoseCourse } from '../../composables/useWhoseCourse';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useCourseExerciseGroupsList } from '../../composables/useCourseExerciseGroupsList';
import ReadListSection from '../layouts/sections/ReadListSection.vue';
import ReadListSectionItem from '../layouts/sections/ReadListSectionItem.vue';
import CourseExerciseGroupTile from './CourseExerciseGroupTile.vue';
import { storeToRefs } from 'pinia';
import DeleteCourseDialog from './dialogs/DeleteCourseDialog.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCourseApprovals } from '../../composables/useCourseApprovals';
import CourseApprovedDialog from './dialogs/CourseApprovedDialog.vue';
import CourseRejectedDialog from './dialogs/CourseRejectedDialog.vue';
import Hint from '../hints/Hint.vue';
import { useUniqueId } from '../../composables/useUniqueId';

const props = defineProps<
{
    courseId:string;
}>();

const { t } = useI18n();
const { toHuman, toEndOfDayISOString } = useCalendar();
const authorizationStore = useAuthorizationStore()
const { canViewCourseTimestamp, canUpdateCourseAvailability, getRoles, canEditCourse, canListCourseUsers, canDeleteCourse, canInvite } = authorizationStore;
const canViewLogs = computed(() => authorizationStore.canViewAuditEvents(course.value?.organizationId));
const { toDisplayEnumName } = useEnum();
const { isLoading } = storeToRefs(useApiClientStore());
const router = useRouter();
const whoseCourse = useWhoseCourse();
const { exerciseGroups, isReady: isListReady } = useCourseExerciseGroupsList(toRef(props.courseId));

const canCreateExerciseGroup = computed(() => useAuthorizationStore().canCreateExerciseGroup(props.courseId, course.value?.organizationId));
const canListCourseApplications = computed(() => useAuthorizationStore().canListCourseApplications(props.courseId, organizationId.value));

const course = ref<ICourse>();
const isTopReady = ref(false);

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                            new BreadcrumbItem(Route.Courses), 
                            new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}})]));

const refreshCourse = async () => 
{
    [course.value] = await Promise.all([useCourseStore().getCourse(props.courseId),
                                        useBusinessUnitStore().fetchBusinessUnits()]);
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId);
    isTopReady.value = true;
}

watch(()=>props.courseId, refreshCourse);
onMounted(refreshCourse);

const {
    canApproveCourses,
    onApproveCourseClicked,
    onRejectCourseClicked,
    showCourseApprovedDialog,
    showCourseRejectedDialog
} = useCourseApprovals(course);
const onCourseApprovedConfirmed = refreshCourse;
const onCourseRejectedConfirmed = refreshCourse;

const isCoursePendingApproval = computed(()=>
{
    return course.value?.status === CourseStatus.PendingApproval;
});
const isCourseRejected = computed(()=>
{
    return course.value?.status === CourseStatus.Rejected;
});
const isDirectPayCOurse = computed<boolean>(() => !!course.value?.enrollmentVendibleId);
const courseCanBeManaged = computed(()=>
{
    return course.value && [CourseStatus.Ready, CourseStatus.PendingApproval, CourseStatus.Rejected].includes(course.value?.status);
});
const showCreation = computed(()=>
{
    return course.value && canViewCourseTimestamp(course.value.id, course.value.organizationId);
});
const showExpiration = computed(()=>
{
    return course.value && canUpdateCourseAvailability(course.value.id, course.value.organizationId)
});
const courseRoles = computed(()=>
{
    return course.value && getRoles(course.value.id, course.value.organizationId).map(role => toDisplayEnumName(UserRole, role)).join(', ')
})
const businessUnitDisplayName = computed(()=>
{
    if(!course.value) return '';
    
    const businessUnit = useBusinessUnitStore().businessUnitsById.get(course.value.businessUnitId);
    return businessUnit ? `${businessUnit.name} - ${businessUnit.region}` : ''; 
})

const organizationId = computed(()=> course.value?.organizationId || "");

const onDeleteButtonClicked = async ()=>
{
    await router.push(whoseCourse.value.topRoute);
}

const showAddEnvironmentHint = computed(()=>
{
    return !!(canCreateExerciseGroup.value === true && isListReady.value === true && exerciseGroups.value?.length === 0);
});
const addEnvironmentHintId = useUniqueId();
const addEnvironmentDescribedByHintId = computed(()=>
{
    return !!showAddEnvironmentHint.value 
        ? addEnvironmentHintId
        : null;
});
const addEnvironmentHintMessages = [t('COURSE_HINTS_NO_ENVIRONMENTS'),t('COURSE_HINTS_ADD_ENVIRONMENT'),t('COURSE_HINTS_USE_ADD_ENVIRONMENT_BUTTON')];

const showAddInstructorHint = computed(()=>
{
    return !!(showAddEnvironmentHint.value === false && canInvite(UserRole.Instructor, props.courseId, course.value?.organizationId) && (course.value?.statistics?.instructors?.length || 0) === 0);
})
const addInstructorHintId = useUniqueId();
const addInstructorDescribedByHintId = computed(()=>{
    return !!showAddInstructorHint.value 
        ? addInstructorHintId
        : null;
});
const addInstructorHintMessages = [t('COURSE_HINTS_NO_INSTRUCTORS'),t('COURSE_HINTS_ADD_INSTRUCTOR'),t('COURSE_HINTS_USE_MANAGE_USERS_BUTTON')];
</script>

