<template>
    <form-layout :title="t('COURSE_USERS_CANVAS_INTEGRATION_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" :confirmText="confirmText" :cancelText="cancelText" @confirm="onConfirmClicked" @cancel="onCancelClicked">
        <template #form>
            <div v-if="selectedApplicationId === undefined">
                <p>{{ t('COURSE_USERS_CANVAS_INTEGRATION_SELECTION_INSTRUCTIONS_TEXT') }}</p>
                <cr-select :label="t('COURSE_USERS_CANVAS_INTEGRATION_SELECTION_LABEL')" v-model="selectedApplicationId" :items="organizationApplicationOptions"/>
            </div>
            <p v-else-if="selectedApplicationId" class="cr-mb0">
                <cr-html :value="t('COURSE_USERS_LINKING_CANVAS_SELECTED_APPLICATION', { name: selectedApplicationName })"/><br/>
                {{ t('COURSE_USERS_LINKING_CANVAS_HELP_TEXT') }} <cr-external-link :href="kbStore.linkingCanvas()">{{ t('COURSE_USERS_LINKING_CANVAS_LINK_TEXT') }}</cr-external-link>
            </p>
            <template v-else-if="showCanvasIntegrationSupportForm">
                <p>{{ t('COURSE_USERS_CONFIGURING_CANVAS_FORM_HELP_TEXT') }} <cr-external-link :href="kbStore.configuringCanvasOrganizationApplicationInformation()" >{{ t('COURSE_USERS_CONFIGURING_CANVAS_FORM_LINK_TEXT') }}</cr-external-link></p>
                <organization-application-form  v-model="organizationApplication"/>
            </template>
            <p v-else>{{ t('COURSE_USERS_CONFIGURING_CANVAS_HELP_TEXT') }} <cr-external-link :href="kbStore.configuringCanvas()">{{ t('COURSE_USERS_CONFIGURING_CANVAS_LINK_TEXT') }}</cr-external-link></p>
        </template>
    </form-layout>
    <support-recipients-dialog v-model="showSupportMessageSentDialog" :recipients="recipients" @confirm="onSupportRecipientsConfirmed"/>
</template>

<script setup lang="ts">
import { IOrganizationApplication, Lti13OrganizationApplication, OrganizationApplicationFilter } from '@cyber-range/cyber-range-api-organization-client';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCourseBreadcrumbs } from '../../../composables/useCourseBreadcrumbs';
import Config from '../../../config';
import { ISupportMessage } from '../../../interfaces/iSupportMessage';
import { ISupportRecipient } from '../../../interfaces/iSupportRecipient';
import { SupportTopic } from '../../../interfaces/supportTopic';
import Route from '../../../routers/route';
import { useCourseStore } from '../../../stores/courseStore';
import { useKbStore } from '../../../stores/kbStore';
import { useOrganizationStore } from '../../../stores/organizationStore';
import { useSessionStore } from '../../../stores/sessionStore';
import { useSupportStore } from '../../../stores/supportStore';
import FormLayout from '../../layouts/FormLayout.vue';
import OrganizationApplicationForm from '../../organizations/Lti13OrganizationApplicationFormSection.vue';
import SupportRecipientsDialog from '../../support/dialogs/SupportRecipientsDialog.vue';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    courseId:string,
}>();

const { t } = useI18n();
const kbStore = useKbStore()
const router = useRouter();

const organizationApplications = ref<IOrganizationApplication[]>([]);
const organizationApplicationOptions = computed(() => 
{
    const missingApplicationOption = new Lti13OrganizationApplication({ id: '', name: t('COURSE_USERS_CANVAS_INTEGRATION_NOT_FOUND_BUTTON') });
    return organizationApplications.value.concat(missingApplicationOption);
});
const selectedApplicationId = ref<string>()
const selectedApplicationName = computed(() => organizationApplicationOptions.value.find(app => app.id === selectedApplicationId.value)?.name);
const organizationApplication = ref(new Lti13OrganizationApplication({ name: 'Canvas Integration' }));
const showCanvasIntegrationSupportForm = computed(() => selectedApplicationId.value === '' && Config.IS_VACR)
const cancelText = computed(() => showCanvasIntegrationSupportForm.value ? t('COURSE_USERS_CANVAS_INTEGRATION_CANCEL_LABEL') : t('COURSE_USERS_CANVAS_INTEGRATION_BACK_LABEL'))
const confirmText = computed(() => showCanvasIntegrationSupportForm.value ? t('COURSE_USERS_CANVAS_INTEGRATION_CONFIRM_LABEL') : false);

const course = ref<ICourse>();

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                          new BreadcrumbItem(Route.Courses), 
                          new BreadcrumbItem({...Route.Course,  text: course?.value?.name, params: {courseId: props.courseId}}), 
                          new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.InviteCourseUsers, text: t('COURSE_USERS_INVITE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.ImportCourseUsersCanvas, params: {courseId: props.courseId }})]));

const onIntegrationNotFoundClicked = () => selectedApplicationId.value = '';
function onCancelClicked()
{
    if (selectedApplicationId.value === undefined)
    {
        router.back();
    }
    else
    {
        selectedApplicationId.value = undefined
    }
}

const isReady = ref(false);
watch(() => props.courseId, async () =>
{
    isReady.value = false;
    course.value = await useCourseStore().getCourse(props.courseId);
}, { immediate: true });

watch(() => course.value?.organizationId, async () =>
{
    if (course.value?.organizationId)
    {
        organizationApplications.value = await useOrganizationStore().listAllOrganizationApplications(new OrganizationApplicationFilter({ organizationId: course.value.organizationId }))
        if (organizationApplications.value.length === 0)
        {
            onIntegrationNotFoundClicked();
        }
        isReady.value = true;
    }
    else
    {
        organizationApplications.value = [];
    }
}, { immediate: true });


const recipients = ref<ISupportRecipient[]>([])
const showSupportMessageSentDialog = ref(false)
async function onConfirmClicked()
{
    const identity = useSessionStore().identity;
    const supportRequest: Partial<ISupportMessage> = {}

    supportRequest.name = identity?.name;
    supportRequest.provider = identity?.provider;

    const body = t('COURSE_USERS_CANVAS_INTEGRATION_SUPPORT_MESSAGE_BODY')
        + Object.entries(organizationApplication.value).filter(([_,value]) => !!value).map(([key,value]) => `${key}: ${value}`).join('\n');

    recipients.value = await useSupportStore().requestSupport({
        topic: SupportTopic.Course,
        topicId: props.courseId,
        topicName: course.value?.name,
        name: identity?.name,
        provider: identity?.provider,
        email: identity?.email,
        subject: t('COURSE_USERS_CANVAS_INTEGRATION_SUPPORT_MESSAGE_SUBJECT'),
        body,
    });
    showSupportMessageSentDialog.value = true;
}

const onSupportRecipientsConfirmed = () =>
{
    router.back();
}
</script>
