<template>
    <read-layout :title="t('COURSE_USERS_INVITATION_CODE_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady">
        <template #top>
            <cr-row>
                <cr-col xs12>
                    <read-section :label="t('COURSE_USERS_INVITATION_CODE_DESCRIPTION_LABEL')" >
                        <cr-row data-testing="invitation-code-description">
                            <cr-col>
                                {{ t('COURSE_USERS_INVITATION_CODE_DESCRIPTION') }}
                            </cr-col>
                        </cr-row>
                        <cr-row data-testing="invitation-code-kb-link">
                            <cr-col class="cr-mt3">
                                <cr-external-link class="kb-link" :href="useKbStore().invitationCode()" data-testing="invitation-code-kb-link" >
                                    {{ t('COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_CODES') }}
                                </cr-external-link>
                            </cr-col>
                        </cr-row>
                    </read-section>
                    <read-section v-if="!courseCode" :label="t('COURSE_USERS_INVITATION_CODE_DETAILS')" class="cr-pt4" data-testing="invitation-code-no-code-details">
                        <cr-row data-testing="invitation-code-kb-link">
                            <cr-col xs12 data-testing="invitation-no-code-label">
                                {{ t('COURSE_USERS_INVITATION_CODE_NO_INVITATION_CODE') }}
                            </cr-col>
                        </cr-row>
                    </read-section>
                    <read-detail-section v-else class="cr-pt4" data-testing="invitation-code-active-code-details">

                        <read-detail-section-item :label="t('COURSE_USERS_INVITATION_CODE_LABEL')" data-testing="invitation-code-active-code-details-code">
                            <span >
                                {{ courseCode }}
                                <cr-clipboard-copy :value="courseCode" :copy-label="t('COURSE_USERS_INVITATION_CODE_CLIPBOARD_COPY')" data-testing="invitation-code-clipboard-copy"/>
                            </span >
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('COURSE_USERS_INVITATION_CODE_EXPIRATION_LABEL')" data-testing="invitation-code-active-code-details-code-expiration">
                                {{ toHuman(codeExpiration)  }}
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_LABEL')" data-testing="invitation-code-active-code-details-reserved-enrollments">
                            {{ estimatedNumberOfUsers }}
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('COURSE_USERS_INVITATION_CODE_REGISTRATION_LINK_LABEL')" data-testing="invitation-code-active-code-details-registration-link">
                            <span class="registration-url">
                                    <a class="kb-link" :href="courseCodeRegistrationUrl" target="_blank">
                                    {{ courseCodeRegistrationUrl }}
                                </a>
                            </span >
                            <cr-clipboard-copy :value="courseCodeRegistrationUrl" :copy-label="t('COURSE_USERS_INVITATION_CODE_REGISTRATION_LINK_CLIPBOARD_COPY')" data-testing="invitation-code-registration-link-clipboard-copy"/>
                        </read-detail-section-item>
                        
                    </read-detail-section>

                    <cr-row>
                        <cr-col xs12 >
                            <cr-external-link class="kb-link" :href="useKbStore().reservedEnrollments()" data-testing="invitation-code-reserved-enrollments-kb-link">
                                {{ t('COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_RESERVED_ENROLLMENTS_LABEL') }}
                            </cr-external-link>
                        </cr-col>
                        <cr-col xs12 class="cr-mt2">
                            <cr-external-link class="kb-link" :href="useKbStore().selfRegistration()" data-testing="invitation-code-self-registration-kb-link">
                                {{ t('COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_SELF_REGISTRATION') }}
                            </cr-external-link>
                        </cr-col>
                    </cr-row>
                </cr-col>
            </cr-row>
        </template>
        <template #controls>
            <read-control-item>
                <cr-button :disabled="isLoading || !!courseCode" @click="onCreateInvitationCodeClicked" outlined data-testing="create-invitation-code-button">
                    <cr-icon>bi-plus</cr-icon>
                    {{ t('COURSE_USERS_INVITATION_CODE_CREATE_INVITATION_CODE_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item>
                <cr-button :disabled="isLoading || !courseCode" @click="onEditReservedEnrollmentsClicked" outlined data-testing="edit-reserved-enrollments-button">
                    <cr-icon>bi-pencil</cr-icon>
                    {{ t('COURSE_USERS_INVITATION_CODE_EDIT_RESERVED_ENROLLMENTS_BUTTON') }}
                </cr-button>
            </read-control-item>
            <read-control-item>
                <cr-button  :disabled="isLoading || !courseCode" @click="onDeleteInvitationCodeClicked" outlined data-testing="delete-invitation-code-button">
                    <cr-icon>bi-trash</cr-icon>
                    {{ t('COURSE_USERS_INVITATION_CODE_DELETE_INVITATION_CODE_BUTTON') }}
                </cr-button>
            </read-control-item>
            <edit-reserved-enrollments-dialog  v-model="showEditReservedEnrollmentsDialog" :courseId="props.courseId" :estimatedNumberOfUsers="estimatedNumberOfUsers" :maxUsersPerCourse="maxUsersPerCourse" @confirm="onEditReservedEnrollmentsDialogConfirmed" @cancel="onEditReservedEnrollmentsDialogCancelled" />
            <invitation-code-created-dialog  v-model="showInvitationCodeCreatedDialog" :code="courseCode" :disabled="isLoading" />
            <delete-invitation-code-dialog  v-model="showDeleteInvitationCodeDialog" :courseId="props.courseId" @confirm="onDeleteInvitationCodeDialogConfirmed"/>
        </template>
    </read-layout>
</template>
<style scoped>
.registration-url
{
    overflow-wrap: anywhere
}
</style>

<script setup lang="ts">
import Config from '../../config';
import EditReservedEnrollmentsDialog from './dialogs/EditReservedEnrollmentsDialog.vue';
import InvitationCodeCreatedDialog from './dialogs/InvitationCodeCreatedDialog.vue';
import DeleteInvitationCodeDialog from './dialogs/DeleteInvitationCodeDialog.vue';
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadSection from '../layouts/sections/ReadSection.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import Route from '../../routers/route';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../stores/apiClientStore';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useCourseStore } from '../../stores/courseStore';
import { useI18n } from 'vue-i18n';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useKbStore } from '../../stores/kbStore';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import { useRouter } from 'vue-router';

const props = defineProps<
{
    courseId:string;
}>();

const { t } = useI18n();
const { toHuman } = useCalendar();

const { isLoading } = storeToRefs(useApiClientStore());

const course = ref<ICourse>();
const courseCode = ref<string>();
const codeExpiration = ref<string>();
const maxUsersPerCourse = ref<number>();
const estimatedNumberOfUsers = ref<number>();


const isReady = ref(false);
const showEditReservedEnrollmentsDialog = ref<boolean>(false);
const showInvitationCodeCreatedDialog = ref<boolean>(false);
const showDeleteInvitationCodeDialog = ref<boolean>(false);

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                          new BreadcrumbItem(Route.Courses), 
                          new BreadcrumbItem({...Route.Course,  text: course?.value?.name, params: {courseId: props.courseId}}), 
                          new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                          new BreadcrumbItem({...Route.InvitationCode, text: t('COURSE_USERS_INVITATION_CODE_PAGE_TITLE'), params: {courseId: props.courseId}})]));


const router = useRouter();
const courseCodeRegistrationUrl = computed(() =>
{
    const path = router.resolve({ name: Route.Registration.name, params: { courseCode: courseCode.value } }).href
    return new URL(path, window.location.origin).href;
});

// Create Code
const onCreateInvitationCodeClicked = async () =>
{
    showInvitationCodeCreatedDialog.value = true;
    await useCourseStore().createCourseCode(props.courseId, { reservedEnrollments: true });
    await refresh();
}

// Edit Enrollments
const onEditReservedEnrollmentsClicked = () =>
{
    showEditReservedEnrollmentsDialog.value = true;
}

const onEditReservedEnrollmentsDialogConfirmed = async () =>{
    await refresh();    
}

const onEditReservedEnrollmentsDialogCancelled = async () =>{
    await refresh();
}

//Delete Code
const onDeleteInvitationCodeClicked = async () =>
{
    showDeleteInvitationCodeDialog.value = true;
}

const onDeleteInvitationCodeDialogConfirmed = async ()=>
{
    await refresh();
}

const refresh = async () => 
{
    try
    {        
        const fetchedCourse:ICourse = await useCourseStore().getCourse(props.courseId);
        const fetchedOrganization:IOrganization = await useOrganizationStore().getOrganization(fetchedCourse.organizationId);
        course.value = fetchedCourse;
        courseCode.value = fetchedCourse?.code?.value;
        codeExpiration.value = fetchedCourse?.code?.expiresAt;
        estimatedNumberOfUsers.value = fetchedCourse?.statistics?.estimatedNumberOfUsers || 0;
        maxUsersPerCourse.value = fetchedOrganization?.limits?.maxUsersPerCourse || 0;
    }
    finally
    {
        isReady.value = true;
    }
}
onMounted(refresh);
</script>