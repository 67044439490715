<template>
    <read-layout :title="t('PROFILE_TITLE')" :breadcrumbs="breadcrumbs" :ready="ready">
        <template #top>
            <cr-row>
                <cr-col xs12 sm12 md12 lg12 xl8>

                    <read-section :label="user.name" data-testing="profile-label">
                        <span>{{ user.email }}</span>
                    </read-section>

                    <read-detail-section :label="t('PROFILE_DETAILS')"  class="cr-mt5" data-testing="profile-details">

                        <read-detail-section-item v-if="user.id" :label="t('PROFILE_DETAILS_ID')" data-testing="profile-details-id">
                            {{ user.id }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="user.lastLoginWith" :label="t('PROFILE_DETAILS_LAST_LOGIN_WITH')" data-testing="profile-details-last-login-with">
                            {{ user.lastLoginWith }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="user.lastLogin" :label="t('PROFILE_DETAILS_LAST_LOGIN')" data-testing="profile-details-last-login">
                            {{ toHuman(user.lastLogin) }}
                        </read-detail-section-item>

                        <read-detail-section-item :label="t('PROFILE_DETAILS_CREATED_TIMESTAMP')" data-testing="profile-details-createdtimestamp">
                            {{ toHuman(user.createdTimestamp) }}
                        </read-detail-section-item>

                        <read-detail-section-item v-if="canViewDevTools() && token" :label="t('PROFILE_DETAILS_TOKEN')" data-testing="profile-details-token">
                            <cr-clipboard-copy :value="token" />
                        </read-detail-section-item>

                    </read-detail-section>

                </cr-col>
            </cr-row>
        </template>

        <template #list>
            <user-memberships-table :user="user" @refresh="onRefreshMembershipTable" @remove="onRemoveFromMembershipTable" />
        </template>

        <template #controls>
            <read-control-item v-if="canImpersonate()">
                <cr-button @click="onImpersonateClicked" :disabled="isMe || isImpersonating || isLoading" outlined data-testing="profile-impersonate-button">
                    <cr-icon>bi-person-badge-fill</cr-icon>
                    {{ t('PROFILE_IMPERSONATE_BUTTON_LABEL') }}
                </cr-button>
            </read-control-item>
            <read-control-item v-if="canViewLogs">
                <cr-button :to="{ name: Route.Logs.name, query: { userId: user.id }}" :disabled="isLoading" outlined data-testing="profile-view-logs-button">
                    <cr-icon>bi-journal-text</cr-icon>
                    {{ t('PROFILE_VIEW_LOGS_BUTTON_LABEL') }}
                </cr-button>
            </read-control-item>
        </template>
    </read-layout>
</template>

<style scoped>
.cr-clipboard-copy:deep(.cr-button)
{
    margin-left: -0.25rem !important;
}
</style>

<script setup lang="ts">
import { IUser, ResourceType, User } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import Route from '../../routers/route';
import { useUserStore } from '../../stores/userStore';
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadSection from '../layouts/sections/ReadSection.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import UserMembershipsTable from './UserMembershipsTable.vue';
import { useCalendar } from '../../composables/useCalendar';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useSessionStore } from '../../stores/sessionStore';
import { useApiClientStore } from '../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import { useRouter } from 'vue-router';
import IProfileMembership from '../../interfaces/iProfileMembership';
import { useTimerStore } from '../../stores/timerStore';
import Config from '../../config';

const { t } = useI18n();
const ready = ref(false);
const { toHuman } = useCalendar();
const authorizationStore = useAuthorizationStore();
const timerStore = useTimerStore();
const { canViewDevTools, canImpersonate } = authorizationStore;
const canViewLogs = computed(() => authorizationStore.canViewAuditEvents(user.value?.organizations?.map((o) => o.organizationId)));
const { isImpersonating } = storeToRefs(useSessionStore());
const { isLoading } = storeToRefs(useApiClientStore());

const breadcrumbs = computed(() => [new BreadcrumbItem(Route.Profile)]);

const props = defineProps<
{
    userId?: string;
}>();

const router = useRouter();
let user = ref<IUser>(new User());
let token = ref<string>('');

onMounted(async () => 
{
    let userStore = useUserStore();

    user.value =  props.userId ?  await userStore.getUser(props.userId) : await userStore.fetchCurrentUser()

    let sessionStore = useSessionStore();

    // Only show token on my profile page (if authorized)
    if(!props.userId || sessionStore.identity?.id === props.userId)
    {
        token.value = sessionStore.session?.token || '';
    }

    ready.value = true;
});

const isMe = computed(()=> user.value.id === useSessionStore().identity?.id);

const onImpersonateClicked = async () =>
{
    if ((await useAuthenticationStore().impersonate(user.value.id)))
    {
        router.push(Route.Home);
    }
}

const onRemoveFromMembershipTable = async(removalData:{membershipToRemove:IProfileMembership, profileMemberships:IProfileMembership[]})=>
{
    if(removalData.membershipToRemove.type === ResourceType.Course)
    {
        user.value.courses = user.value.courses.filter(courseMembership=> removalData.membershipToRemove.id !== courseMembership.id);
    }
    else if(removalData.membershipToRemove.type === ResourceType.Organization)
    {
        const organizationId = removalData.membershipToRemove.id;
        const courseIdsToRemove:string[] = [];
        for(let membership of removalData.profileMemberships)
        {
            if(membership.type === ResourceType.Course && membership.organizationId  === organizationId)
            {
                courseIdsToRemove.push(membership.id)
            }
        }
        user.value.organizations = user.value.organizations.filter(organizationMembership=> organizationId !== organizationMembership.organizationId);
        user.value.courses = user.value.courses.filter(courseMembership => !courseIdsToRemove.includes(courseMembership.id));
    }

    // set refresh into the future 
    timerStore.schedule(()=>onRefreshMembershipTable(false),[],Config.PROFILE_MEMBERSHIP_REMOVAL_REFRESH_DELAY_IN_MS, false);
};

const onRefreshMembershipTable = async(manageReadiness:boolean = true)=>
{
    if(manageReadiness)
    {
        ready.value = false;
    }
    let userStore = useUserStore();
    user.value =  props.userId ?  await userStore.getUser(props.userId) : await userStore.fetchCurrentUser()
    ready.value = true;

};
</script>