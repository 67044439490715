import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { Ref, watchEffect } from "vue";
import Route from "../routers/route";
import { useAuthorizationStore } from "../stores/authorizationStore";
import { useWhoseCourse } from "./useWhoseCourse";

export function useCourseBreadcrumbs(breadcrumbs: Ref<IBreadcrumbItem[]>): Ref<IBreadcrumbItem[]>
{
    let whoseCourse = useWhoseCourse();

    watchEffect(() =>
    {
        if(whoseCourse.value.mine)
        {
            for(let item of breadcrumbs.value)
            {
                let toRoute = Object.values(Route).find(route => route.name === item.name);
    
                if(toRoute.mine)
                {
                    Object.assign(item, toRoute.mine);
                }
            }
        }

        if (useAuthorizationStore().canNavigateOutsideCourse() === false)
        {
            breadcrumbs.value = breadcrumbs.value.filter(item => item.name !== Route.Courses.name && item.name !== Route.MyCourses.name);
        }
    });

    return breadcrumbs;
}