<template>
        <table-layout :title="t('PLANS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">

            <template #table> 
                <cr-table :items="plans" :headers="headers" actionable class="cr-pb6" :loading="isLoading">
                    
                    <template v-slot:action="{item}">
                        <cr-table-action-item v-if="canViewProduct()" :item="item" :to="{name: Route.Plan.name, params: {productId: item.productId}}" icon="bi-file-earmark" data-testing="plans-view-plan-action-item">
                            {{ t('PLANS_TABLE_VIEW_PLAN_ACTION_ITEM') }}
                        </cr-table-action-item>
                    </template>

                    <template v-slot:isFlexRate="{item}">
                        <template v-if="item.isFlexRate">
                            {{ t('PLANS_TABLE_FLEX_RATE') }}
                        </template>
                        <template v-else>
                            {{ t('PLANS_TABLE_FLAT_RATE') }}
                        </template>
                    </template>

                    <template v-slot:provideCourseware="{item}">
                        <template v-if="item.provideCourseware">
                            {{ t('PLANS_TABLE_PROVIDE_COURSEWARE') }}
                        </template>
                    </template>

                    <template v-slot:provideExerciseArea="{item}">
                        <template v-if="item.provideExerciseArea">
                            {{ t('PLANS_TABLE_PROVIDE_EXERCISE_AREA') }}
                        </template>
                    </template>

                    <template v-slot:provideCtf="{item}">
                        <template v-if="item.provideCtf">
                            {{ t('PLANS_TABLE_PROVIDE_CTF') }}
                        </template>
                    </template>

                    <template v-slot:persistent="{ item }">
                        <template v-if="item.providePersistentEnvironment && item.provideNonPersistentEnvironment">
                            {{ t('PLANS_TABLE_PROVIDE_BOTH_PERSISTENT_AND_NON_PERSISTENT_ENVIRONMENT') }}
                        </template>
                        <template v-else-if="item.providePersistentEnvironment">
                            {{ t('PLANS_TABLE_PROVIDE_PERSISTENT_ENVIRONMENT') }}
                        </template>
                       <template v-else-if="item.provideNonPersistentEnvironment">
                            {{ t('PLANS_TABLE_PROVIDE_NON_PERSISTENT_ENVIRONMENT') }}
                        </template>
                    </template>

                </cr-table>
            </template>

            <template #controls>
                <list-control-item>
                    <cr-button :to="Route.Features" outlined data-testing="plans-manage-features-button">
                        <cr-icon>bi-puzzle</cr-icon>
                        {{ t('PLANS_MANAGE_FEATURES_BUTTON') }}
                    </cr-button>
                </list-control-item>
        </template>
        </table-layout>
</template>


<script setup lang="ts">
import { BreadcrumbItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import ListControlItem from '../layouts/sections/ListControlItem.vue';
import { computed, onMounted, ref } from 'vue';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import { usePlanStore } from '../../stores/planStore';
import { storeToRefs } from 'pinia';
import { useNumberFormats } from '../../composables/useNumberFormats';
import { IPlan } from '../../interfaces/iPlan';
import { useAuthorizationStore } from '../../stores/authorizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canViewProduct } = useAuthorizationStore();

const breadcrumbs = computed(() => [
                            new BreadcrumbItem(Route.Plans)
                         ]);
                         
const headers = [
    new TableHeaderItem({ text: t('PLANS_TABLE_NAME'), key: 'name'}),
    new TableHeaderItem({ text: t('PLANS_TABLE_TYPE'), key: 'isFlexRate', align: 'center'}),
    new TableHeaderItem({ text: t('PLANS_TABLE_ENROLLMENT_FEE'), key: 'pricePerEnrollment', align: 'center', formatter: useNumberFormats().toCurrency}),
    new TableHeaderItem({ text: t('PLANS_TABLE_COURSEWARE'), key: 'provideCourseware', align: 'center'}),
    new TableHeaderItem({ text: t('PLANS_TABLE_EXERCISE_AREA'), key: 'provideExerciseArea', align: 'center'}),
    new TableHeaderItem({ text: t('PLANS_TABLE_PERSISRENCE'), key: 'persistent', align: 'center'}),
    new TableHeaderItem({ text: t('PLANS_TABLE_CTF'), key: 'provideCtf', align: 'center'}),
];

const ready = ref<boolean>(false);

const plans = ref<IPlan[]>([])

onMounted(async ()=>
{
    let planStore = usePlanStore();

    await planStore.fetchPlansAndFeatures();
    plans.value = planStore.allPlans().sort((a,b)=>(a.name || '') < (b.name || '') ? -1 : 1);
    
    ready.value = true;  
})
</script>