<template>
    <form-layout :title="t('CREATE_CREDENTIALS_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="isReady" :confirm-disabled="confirmDisabled" @confirm="onConfirm" @cancel="onCancel">
        <template #top>
            <p data-testing="create-credential-information">{{ t('CREATE_CREDENTIALS_INFORMATION_TEXT') }}</p>
        </template>
        <template #form>
            <form-section data-testing="credential-form-information-section" class="cr-mb5">
                <cr-select v-model="selectedRole" :items="availableRoles" :label="t('CREATE_CREDENTIALS_ROLE_LABEL')" required data-testing="create-course-credentials-role-select"/>
                <cr-text-field v-model="numberOfCredentialsToCreate" type="number" :label="t('CREATE_CREDENTIALS_NUMBER_CREDENTIALS_LABEL')" required :min="0" :max="maximumNumberOfCredentials" data-testing="create-course-credentials-credentials-to-create"/>
            </form-section>
            <download-credentials-dialog v-model="showDownloadCredentialsDialog" :successes="credentials.length" :total="Number(numberOfCredentialsToCreate)" :errors="failedCount" @confirm="onDownloadDialogConfirmed"/>
        </template>
    </form-layout>
</template>

<script setup lang="ts">
import { ICourse, User, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { computed, onMounted, ref, watch } from 'vue';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCourseStore } from '../../stores/courseStore';
import { IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { useOrganizationStore } from '../../stores/organizationStore';
import FormLayout from '../layouts/FormLayout.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import { useI18n } from 'vue-i18n';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { useEnum } from '../../composables/useEnum';
import { useRouter } from 'vue-router';
import { ICredential } from '@cyber-range/cyber-range-api-credential-client';
import { useCredentialStore } from '../../stores/credentialStore';
import DownloadCredentialsDialog from './dialogs/DownloadCredentialsDialog.vue';
import FormSection from '../layouts/sections/FormSection.vue';
import { useDownload } from '../../composables/useDownload';

const props = defineProps<{
    courseId: string
}>();

const { t } = useI18n();
const router = useRouter();
const isReady = ref(false);

const course = ref<ICourse>();

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                        new BreadcrumbItem(Route.Courses), 
                        new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}), 
                        new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                        new BreadcrumbItem({...Route.CreateCourseCredentials, text: t('CREATE_CREDENTIALS_PAGE_TITLE'), params: {courseId: props.courseId}})
                        ]));

const { canInvite } = useAuthorizationStore();

const maximumNumberOfCredentials = computed(() => 
{
    if (!(organization.value?.limits && course.value?.statistics ))
    {
        return 0;
    }

    const courseUsersAllowed = organization.value.limits.maxUsersPerCourse - course.value.statistics.numberOfUsers;

    let chosenRoleAllowed = 0;
    if (selectedRole.value === UserRole.Student)
    {
        chosenRoleAllowed = organization.value.limits.maxStudentsPerCourse - course.value.statistics.numberOfStudents;
    }
    else if (selectedRole.value === UserRole.TA)
    {
        chosenRoleAllowed = organization.value.limits.maxTasPerCourse - course.value.statistics.numberOfTas
    }

    return Math.min(courseUsersAllowed, chosenRoleAllowed);
})

const organization = ref<IOrganization>();
const selectedRole = ref<UserRole>(UserRole.Student);
const numberOfCredentialsToCreate = ref<number>(0);
const processedCount = computed(() => credentials.value.length + failedCount.value );
const credentials = ref<ICredential[]>([]);
const failedCount = ref<number>(0);
const showDownloadCredentialsDialog = ref<boolean>(false);
const confirmDisabled = computed(() => numberOfCredentialsToCreate.value < 1);

const availableRoles = computed(()=>
{
   let results = [];

   if(canInvite(UserRole.Student, props.courseId, course.value?.organizationId))
    {
        results.push(UserRole.Student);
    }
    if(canInvite(UserRole.TA, props.courseId, course.value?.organizationId))
    {
        results.push(UserRole.TA);
    } 

    return useEnum().toSelectItems(UserRole, results);
});

const resetCredentialsToCreateValue = () => numberOfCredentialsToCreate.value = maximumNumberOfCredentials.value > 0 ? 1 : 0;

watch(() => selectedRole.value, () =>
{
    resetCredentialsToCreateValue();
})

const download = () =>
{
    const headers = ['role,name,password'];
    const csvData = headers.concat(credentials.value.map(c => `${selectedRole.value},${c.username},${c.password}`)).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    useDownload().downloadFromBlob(blob, 'credentials.csv');
}

const batchCreate = async () =>
{
    failedCount.value = 0;
    
    let batchSize = 5;

    let promises: Promise<void>[] = [];
    while(processedCount.value + promises.length < numberOfCredentialsToCreate.value)
    {
        promises.push(createCredential());
        if (promises.length === batchSize || processedCount.value + promises.length === numberOfCredentialsToCreate.value)
        {
            await Promise.all(promises);
            promises = [];
        }
    }
}

const createCredential = async () =>
{
    try
    {
        const credential = await useCredentialStore().create({ role: selectedRole.value, courseId: props.courseId }, {background: true});
        credentials.value.push(credential);
    }
    catch(e)
    {
        failedCount.value++;
    }
}

const onConfirm = async () => 
{
    // Start creation and open dialog
    showDownloadCredentialsDialog.value = true;
    batchCreate();
}

const onCancel = async () => 
{
    router.push({ name: Route.CourseUsers.name, params: {courseId: props.courseId}})
}

const onDownloadDialogConfirmed = () =>
{
    download();
    router.push({ name: Route.CourseUsers.name, params: {courseId: props.courseId}})
}

onMounted(async ()=>
{
    try
    {
        course.value = await useCourseStore().getCourse(props.courseId);
        organization.value = await useOrganizationStore().getOrganization(course.value.organizationId);
        await useOrganizationStore().fetchOrganizationNameAndLogo([organization.value]);
        resetCredentialsToCreateValue();
    }
    finally
    {
        isReady.value = true;
    }
});

</script>