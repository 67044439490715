<template>
    <table-layout :title="t('SYNC_COURSE_USERS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
        <template #filter>
            <table-filter-section>
                <table-filter-section-item>
                    <between-date-filter v-model:from-date="fromDate" v-model:to-date="toDate" :label="t('SYNC_COURSE_USERS_DATE_FILTER_LABEL')"/>
                </table-filter-section-item>
            </table-filter-section>
        </template>
        <template #table>
            <cr-table :items="items" :headers="headers" actionable :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" data-testing="sync-course-users-history-table">
                <template v-slot:action="{item}">
                    <cr-table-action-item :item="item" @click="onViewHistoryActionItemClicked" icon="bi-file-text" data-testing="sync-course-users-view-history-action">
                        {{ t('SYNC_COURSE_USERS_VIEW_HISTORY_ACTION') }}
                    </cr-table-action-item>
                </template>
                <template v-slot:status="{value}">
                    <sync-course-users-status-chip :status="value"/>
                </template>
            </cr-table>
        </template>
        <template #pagination>
            <cr-load-more-button v-if="!finishedPaging" :loading="loadingMoreItems || isLoading" @click="onMoreItemsButtonClicked"/>
        </template>
        <template #controls>
            <table-control-item>
                <cr-button :disabled="scheduleIsRunning || isLoading" outlined data-testing="sync-now-button" @click="onSyncNowClicked">
                    <cr-icon>bi-play-fill</cr-icon>
                    {{ t('SYNC_COURSE_USERS_SYNC_NOW_BUTTON') }}
                </cr-button>
            </table-control-item>
            <schedule-history-details-dialog :history="selectedItem" v-model="showHistoryDetailsDialog" />
        </template>
    </table-layout>
</template>


<script setup lang="ts">
import { BreadcrumbItem, TableHeaderItem} from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onMounted, ref, watch, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import Route from '../../routers/route';
import { useCalendar } from '../../composables/useCalendar';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { useCourseStore } from '../../stores/courseStore';
import { useSyncStore } from '../../stores/syncStore';
import { ISchedule, IScheduleHistory, IScheduleHistoryFilter, IScheduleHistoryStatistics, Schedule, ScheduleHistoryFilter, ScheduleStatus } from '@cyber-range/cyber-range-api-sync-client';
import SyncCourseUsersStatusChip from './SyncCourseUsersStatusChip.vue';
import { useTimerStore } from '../../stores/timerStore';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue';
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue';
import BetweenDateFilter from '../filters/BetweenDateFilter.vue';
import Config from '../../config';
import ScheduleHistoryDetailsDialog from './dialogs/ScheduleHistoryDetailsDialog.vue';
import TableControlItem from '../layouts/sections/TableControlItem.vue';
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { toHuman } = useCalendar();

const props = defineProps<
{
   courseId:string,
}>();

type ScheduleHistoryView = IScheduleHistory & IScheduleHistoryStatistics;

const toScheduleHistoryView = (history: IScheduleHistory): ScheduleHistoryView =>
{
    return {...history, ...history.statistics};
}

const suggestedNumberOfItems = ref<number>(Config.DEFAULT_UNIDIRECTIONAL_PAGING_PAGE_SIZE);
const course = ref<ICourse>();
const schedule = ref<ISchedule>(new Schedule());
let items = ref<ScheduleHistoryView[]>([]);
let filter = ref<IScheduleHistoryFilter>(new ScheduleHistoryFilter());
let fromDate = ref<string>("");
let toDate = ref<string>("");
let finishedPaging = ref<boolean>(false);
let loadingMoreItems = ref<boolean>(false);

const scheduleId = computed(() => course.value?.sync.syncScheduleId || "");

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
                            new BreadcrumbItem(Route.Courses), 
                            new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
                            new BreadcrumbItem({...Route.CourseUsers, text: t('COURSE_USERS_PAGE_TITLE'), params: {courseId: props.courseId}}),
                            new BreadcrumbItem({...Route.SyncCourseUsers, params:{courseId: props.courseId}}) ]));

const headers = [
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_TIMESTAMP'), key: 'createdTimestamp', sortable: false, formatter: toHuman}),
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_STATUS'), key: 'status', sortable: false, align: 'center'}),
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_ADDED'), key: 'added', sortable: false}),
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_UPDATED'), key: 'updated', sortable: false}),
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_DELETED'), key: 'deleted', sortable: false}),
    new TableHeaderItem({ text: t('SYNC_COURSE_USERS_MESSAGE'), key: 'message', sortable: false}),
];

watch([fromDate, toDate], async ([fromValue, toValue]) =>
{
    filter.value.from = fromValue;
    filter.value.to = toValue;
    await clearAndRefetchHistories();
})

const scheduleIsRunning = computed(() => 
{
    return schedule.value?.lastRunStatus === ScheduleStatus.PendingRunning;
})

const pollScheduleHistories = async () =>
{
    await Promise.all([clearAndRefetchHistories({limit: items.value.length}, true), fetchSchedule(true)])
    if (!scheduleIsRunning.value)
    {
        teardownPolling();
    }
}

let timerId: string;
const setupPolling = () =>
{
    if (!timerId)
    {
        timerId = useTimerStore().schedule(pollScheduleHistories, undefined, 5000, true);
    }
}

const teardownPolling = () =>
{
    useTimerStore().unschedule(timerId);
    timerId = "";
}

const clearAndRefetchHistories = async (data?: {}, background?: boolean) => 
{
    finishedPaging.value = false;
    filter.value.token = "";

    const page = await useSyncStore().listScheduleHistories(scheduleId.value, new ScheduleHistoryFilter({...toRaw(filter.value), ...data}), {background});
    items.value = page.items.map(history => toScheduleHistoryView(history)); // Don't append new items, replace existing
    filter.value.token = page.nextPageToken;
    finishedPaging.value = !filter.value.token;
}

const loadMoreScheduleHistories = async (data = {}, background = false) =>
{
    if (finishedPaging.value)
    {
        return;
    }
    const page = await useSyncStore().listScheduleHistories(scheduleId.value, new ScheduleHistoryFilter({...toRaw(filter.value), ...data}), {background});
    items.value.push(...page.items.map(history => toScheduleHistoryView(history)));
    filter.value.token = page.nextPageToken;
    finishedPaging.value = !filter.value.token;
}

const fetchSchedule = async (background?: boolean) => 
{
    schedule.value = await useSyncStore().getOneSchedule(scheduleId.value, {background});
}

const onMoreItemsButtonClicked = async () =>
{
    loadingMoreItems.value = true;
    await loadMoreScheduleHistories();
    loadingMoreItems.value = false;
}

const onSyncNowClicked = async () =>
{
    schedule.value.lastRunStatus = ScheduleStatus.PendingRunning;
    await useSyncStore().runSchedule(scheduleId.value);
    await clearAndRefetchHistories({limit: items.value.length + 1});  // Keep the same number of items on the page + 1 for the newly created history.
    await setupPolling();
}

const onSuggestedNumberOfItems = (n:number) =>
{
    suggestedNumberOfItems.value = n;
}

const initializeFilter = () =>
{
    filter.value = new ScheduleHistoryFilter({limit: suggestedNumberOfItems.value});
}

const selectedItem = ref<IScheduleHistory>();
const showHistoryDetailsDialog = ref<boolean>(false);
const onViewHistoryActionItemClicked = async (item: ScheduleHistoryView) => 
{
    selectedItem.value = await useSyncStore().getOneScheduleHistory(scheduleId.value, item.id);
    showHistoryDetailsDialog.value = true;
}

onMounted(async () => 
{
    initializeFilter(); // Do this in onMounted as suggestedNumberOfItems has been set at this point.
    course.value = await useCourseStore().getCourse(props.courseId);
    await Promise.all([fetchSchedule(), loadMoreScheduleHistories(), useOrganizationStore().fetchOrganizationNameAndLogo(course.value.organizationId)]);
    if (scheduleIsRunning.value)
    {
        setupPolling();
    }
});

onBeforeUnmount(() =>
{
    teardownPolling();
})

</script>