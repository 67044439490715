<template>
    <read-layout :title="t('EXERCISE_GROUP_CREATE_PAGE_TITLE')" :breadcrumbs="breadcrumbs" :ready="true">
        <template #top>
            <catalog-family-search v-model="families" :organizationIds="searchOrganizationIds" @loading="onLoading" class="cr-mb5"/>
            <div ref="container" class="results-container" :class="{ 'container-centered': isLoading || !filteredFamilies || filteredFamilies.length === 0 }">
                <cr-loading v-if="isLoading" class="cr-mb5"/>
                <read-list-section v-show="!isLoading">
                    <read-list-section-item v-for="family in filteredFamilies" :key="family.id">
                        <catalog-family-tile :family="family" :to="{ name: Route.CreateExerciseGroupStep2.name, params: { courseId, catalogFamilyId: family.id } }" />
                    </read-list-section-item>
                </read-list-section>
            </div>
        </template>
    </read-layout>
</template>

<style scoped>
.results-container {
    min-height: calc(100vh - v-bind(containerTopOffset) - 3.5rem);
}
.container-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<script setup lang="ts">
import { ICatalogFamily, CatalogFamilySearchFilter } from '@cyber-range/cyber-range-api-catalog-client';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem, useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { computed, onMounted, ref, watch } from 'vue';
import { useCourseBreadcrumbs } from '../../composables/useCourseBreadcrumbs';
import Route from '../../routers/route';
import { useCourseStore } from '../../stores/courseStore';
import ReadLayout from '../layouts/ReadLayout.vue';
import ReadListSection from '../layouts/sections/ReadListSection.vue';
import ReadListSectionItem from '../layouts/sections/ReadListSectionItem.vue';
import CatalogFamilySearch from './CatalogFamilySearch.vue';
import CatalogFamilyTile from './CatalogFamilyTile.vue';
import { useSessionStore } from '../../stores/sessionStore';
import { useI18n } from 'vue-i18n'
import { useOrganizationStore } from '../../stores/organizationStore';

const { t } = useI18n();

const props = defineProps<
{
    courseId: string;
}>();

const families = ref<ICatalogFamily[]>();
const course = ref<ICourse>();
const searchOrganizationIds = ref<string[]>([CatalogFamilySearchFilter.GLOBAL_ENTRY_ORGANIZATION_ID])

const isLoading = ref(true);
const onLoading = (value: boolean) => isLoading.value = value;

const container = ref<HTMLElement|null>(null);
const containerTopOffset = ref('0px');

// Filter out catalog entries that aren't global or belong to the user
const currentUserId = useSessionStore().identity?.id;
const filteredFamilies = computed(() => families.value?.filter(family => !family.userId || family.userId === currentUserId));

onMounted(async () =>
{
    const top = (container.value?.getBoundingClientRect()?.top || 0) + window.scrollY;
    containerTopOffset.value = `${top}px`;
});

watch(()=>props.courseId, async () =>
{
    course.value = await useCourseStore().getCourse(props.courseId);
    await useOrganizationStore().fetchOrganizationNameAndLogo(course.value?.organizationId);
    searchOrganizationIds.value = [CatalogFamilySearchFilter.GLOBAL_ENTRY_ORGANIZATION_ID, course.value?.organizationId]
}, { immediate: true });

let lastAnnouncement = '';
const announcementStore = useAnnouncementStore();
watch([isLoading, filteredFamilies], () =>
{
    if (!filteredFamilies.value) return

    let announcement;
    if (isLoading.value)
    {
        announcement = t('EXERCISE_GROUP_CREATE_ANNOUNCEMENT_NOW_LOADING');
    }
    else
    {
        announcement = t('EXERCISE_GROUP_CREATE_ANNOUNCEMENT_SHOWING_ENVIRONMENTS', { count: filteredFamilies.value.length })
    }

    if (announcement != lastAnnouncement)
    {
        lastAnnouncement = announcement;
        announcementStore.announce(announcement)
    }
}, { immediate: true, deep: true });

const breadcrumbs = useCourseBreadcrumbs(computed(() => [
    new BreadcrumbItem(Route.Courses), 
    new BreadcrumbItem({...Route.Course,  text: course.value?.name, params: {courseId: props.courseId}}),
    new BreadcrumbItem(Route.CreateExerciseGroup),
]));
</script>
